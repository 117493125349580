import React from "react";
import { ReactComponent as TrustpilotStars0 } from "../images/vectors/trustpilot-stars-0.svg";
import { ReactComponent as TrustpilotStars1 } from "../images/vectors/trustpilot-stars-1.svg";
import { ReactComponent as TrustpilotStars15 } from "../images/vectors/trustpilot-stars-1-5.svg";
import { ReactComponent as TrustpilotStars2 } from "../images/vectors/trustpilot-stars-2.svg";
import { ReactComponent as TrustpilotStars25 } from "../images/vectors/trustpilot-stars-2-5.svg";
import { ReactComponent as TrustpilotStars3 } from "../images/vectors/trustpilot-stars-3.svg";
import { ReactComponent as TrustpilotStars35 } from "../images/vectors/trustpilot-stars-3-5.svg";
import { ReactComponent as TrustpilotStars4 } from "../images/vectors/trustpilot-stars-4.svg";
import { ReactComponent as TrustpilotStars45 } from "../images/vectors/trustpilot-stars-4-5.svg";
import { ReactComponent as TrustpilotStars5 } from "../images/vectors/trustpilot-stars-5.svg";

// text wording from: https://support.trustpilot.com/hc/en-us/articles/201748946
const trustpilotStars = (num) => {
	switch (num) {
		case 5:
			return {
				el: <TrustpilotStars5 />,
				text: "Excellent",
			};
		case 4.5:
			return {
				el: <TrustpilotStars45 />,
				text: "Excellent",
			};
		case 4:
			return {
				el: <TrustpilotStars4 />,
				text: "Great",
			};
		case 3.5:
			return {
				el: <TrustpilotStars35 />,
				text: "Average",
			};
		case 3:
			return {
				el: <TrustpilotStars3 />,
				text: "Average",
			};
		case 2.5:
			return {
				el: <TrustpilotStars25 />,
				text: "Poor",
			};
		case 2:
			return {
				el: <TrustpilotStars2 />,
				text: "Poor",
			};
		case 1.5:
			return {
				el: <TrustpilotStars15 />,
				text: "Bad",
			};
		case 1:
			return {
				el: <TrustpilotStars1 />,
				text: "Bad",
			};
		default:
			return {
				el: <TrustpilotStars0 />,
				text: "Bad",
			};
	}
};

export default trustpilotStars;
